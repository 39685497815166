.react-tour-mask {
  opacity: 0.2;
}

.react-tour-helper {
  padding: 20px !important;
}

.react-tour-helper button[aria-label="Close"] {
  top: 11px;
  right: 13px;
}
