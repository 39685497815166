.app {
  text-align: center;
}

.app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.connect-wallet {
  background: #47a1ff;
  padding: 10px 20px;
  border: none;
  border-radius: 70px;
  font-weight: 500;
}
