.flip > section > span {
  padding: 0rem 0.5rem;
  background-color: #16151a;
}

.flip > section > span > span {
  font-size: 67px !important;
}

.flip > section > span > span > span {
  font-size: 67px !important;
}

#jackpot-flip1 > section > span > span > span {
  background: linear-gradient(92.48deg, #1967fc 1.43%, #19fcb8 75%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
}

#jackpot-flip2 > section > span > span > span {
  background: radial-gradient(72.13% 132.54% at 27.87% 38.89%, #24ff00 0%, #fee600 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
}

#jackpot-flip3 > section > span > span > span {
  background: linear-gradient(337.35deg, #eb00ff 7.34%, #e9b80c 91.96%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
}

.jackpot-winner-modal > section > span {
  padding: 0.5rem 1rem;
  background-color: #16151a;
}

.jackpot-winner-modal > section > span > span > span {
  font-size: 90px !important;
  font-weight: 700;
}

@media screen and (max-width: 899px) {
  .jackpot-winner-modal > section > span {
    padding: 0.5rem 2vw;
  }
}

@media screen and (max-width: 599px) {
  .flip > section > span {
    padding: 0rem 1.25vw !important;
  }
}
